import { admin2 } from './instance'

// GET admin.entryControlConfig.singleFind
export const GetEntryControlConfig = async ({
  shopId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/entryControlConfig`,
  })
}
// PUT admin.entryControlConfig.singleUpdate
export const UpdateEntryControlConfig = async ({
  shopId,
  enable,
  entrySetting,
  entitlementSelectionOrder,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/entryControlConfig`,
    data: {
      enable,
      entrySetting,
      entitlementSelectionOrder,
    },
  })
}
